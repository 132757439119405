/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React from "react";
import tw, { css } from "twin.macro";
import Grid from "~components/styles/Grid.jsx";
import * as T from "~components/styles/Typography.jsx";
import Image from "~components/Image.jsx";
import PortableText from "~components/PortableText.jsx";

const TextImageThreeColumn = ({ data }) => {
  const { columnOne, columnTwo, imageColumn } = data;

  //

  return (
    <section css={[tw`relative block`]}>
      <div
        css={[
          css`
            width: 50vw;
            background: ${columnOne?.backgroundColor?.hex || `white`};
          `,
          tw`h-full absolute top-0 left-0 bottom-0 hidden md:block`
        ]}
      />

      <div
        css={[
          css`
            width: 50vw;
            background: ${columnTwo?.backgroundColor?.hex || `white`};
          `,
          tw`h-full absolute top-0 right-0 bottom-0 hidden md:block`
        ]}
      />

      {/*  */}

      <Grid>
        <article
          css={[
            css`
              background: ${columnOne?.backgroundColor?.hex || `white`};
              color: ${columnOne?.textColor?.hex || `black`};
            `,
            tw`col-span-full md:col-span-8 relative py-16 px-0 md:p-16`
          ]}
        >
          <div
            css={[
              css`
                width: calc(100% + 2rem);
                left: -1rem;
                background: ${columnOne?.backgroundColor?.hex};
                z-index: -10;
              `,
              tw`h-full absolute top-0 bottom-0 md:hidden`
            ]}
          />

          <T.Head font="3">{columnOne.header}</T.Head>

          <PortableText blocks={columnOne._rawBody} _css={[tw`mt-8`]} />
        </article>

        <figure
          css={[
            css`
              background: ${imageColumn?.backgroundColor?.hex || `white`};
            `,
            tw`col-span-full md:col-span-8 relative pt-24`
          ]}
        >
          <div
            css={[
              css`
                width: calc(100% + 2rem);
                left: -1rem;
                background: ${imageColumn?.backgroundColor?.hex || `white`};
                z-index: -10;
              `,
              tw`h-full absolute top-0 bottom-0 md:hidden`
            ]}
          />

          <Image image={imageColumn.image} />
        </figure>

        <article
          css={[
            css`
              background: ${columnTwo?.backgroundColor?.hex || `white`};
              color: ${columnTwo?.textColor?.hex || `black`};
            `,
            tw`col-span-full md:col-span-8 relative py-16 px-0 md:p-16`
          ]}
        >
          <div
            css={[
              css`
                width: calc(100% + 2rem);
                left: -1rem;
                background: ${columnTwo?.backgroundColor?.hex || `white`};
                z-index: -10;
              `,
              tw`h-full absolute top-0 bottom-0 md:hidden`
            ]}
          />

          <T.Head font="3">{columnTwo.header}</T.Head>

          <PortableText blocks={columnTwo._rawBody} _css={[tw`mt-8`]} />
        </article>
      </Grid>
    </section>
  );
};

export default TextImageThreeColumn;
