/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React from "react";
import { graphql } from "gatsby";
import BannerSection from "~components/sections/BannerSection.jsx";
import FactCardsSection from "~components/sections/FactCardsSection.jsx";
import FormAnnualReport from "~components/sections/FormAnnualReport.jsx";
import InstagramSection from "~components/sections/InstagramSection.jsx";
import HeadingSection from "~components/sections/HeadingSection.jsx";
import HeaderTextSection from "~components/sections/HeaderTextSection.jsx";
import TextImageThreeColumn from "~components/sections/TextImageThreeColumn.jsx";
import TextFourColumn from "~components/sections/TextFourColumn.jsx";
import ImageSection from "~components/sections/ImageSection.jsx";
import Footer from "~components/Footer.jsx";
import Layout from "~components/Layout.jsx";
import SEO from "~components/SEO.jsx";

const AboutPage = ({ data, location }) => {
  // ===========================================================================
  // variables

  const cms = data.sanityAboutPage;

  // ===========================================================================
  // render

  return (
    <>
      <SEO
        customTitle={cms?.title}
        customDescription={cms?.seoDescription}
        customKeywords={cms?.seoKeywords}
        path={location.pathname}
      />

      <Layout>
        <BannerSection data={cms.atfBanner} />

        <HeadingSection data={cms.headerSectionOne} colorChange />

        <HeaderTextSection data={cms.headerTextSection} />

        <ImageSection data={cms.imageSectionOne} />

        <TextImageThreeColumn data={cms.textTwoColImage} />

        <HeadingSection data={cms.headerSectionTwo} />

        <FactCardsSection data={cms.cards3Up} />

        <ImageSection data={cms.imageSectionTwo} />

        <TextFourColumn data={cms.textKeypoints} />

        <FormAnnualReport />

        <InstagramSection />
      </Layout>

      <Footer />
    </>
  );
};

export default AboutPage;

export const query = graphql`
  query AboutPage {
    sanityAboutPage {
      title

      #

      atfBanner {
        textColor {
          hex
        }
        backgroundColor {
          hex
        }
        header
        subheader
        image {
          altText
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }

      #

      headerSectionOne {
        textColor {
          hex
        }
        backgroundColor {
          hex
        }
        header
        subheader
      }

      #

      headerTextSection {
        textColor {
          hex
        }
        backgroundColor {
          hex
        }
        header
        body
        button {
          link
          text
        }
      }

      #

      imageSectionOne {
        backgroundColor {
          hex
        }
        image {
          altText
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }

      #

      textTwoColImage {
        columnOne {
          textColor {
            hex
          }
          backgroundColor {
            hex
          }
          header
          _rawBody
        }
        columnTwo {
          textColor {
            hex
          }
          backgroundColor {
            hex
          }
          header
          _rawBody
        }
        imageColumn {
          backgroundColor {
            hex
          }
          image {
            altText
            asset {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }

      #

      headerSectionTwo {
        textColor {
          hex
        }
        backgroundColor {
          hex
        }
        header
        subheader
      }

      #

      cards3Up {
        cards {
          _key
          backgroundColor {
            hex
          }
          image {
            altText
            asset {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
          textColor {
            hex
          }
          title
          body
        }
      }

      #

      imageSectionTwo {
        backgroundColor {
          hex
        }
        image {
          altText
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }

      #

      textKeypoints {
        textColor {
          hex
        }
        backgroundColor {
          hex
        }
        header
        subheader
        points
      }

      #

      seoDescription
      seoKeywords
    }
  }
`;
