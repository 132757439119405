/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React from "react";
import tw, { css } from "twin.macro";
import * as T from "~components/styles/Typography.jsx";
import Image from "~components/Image.jsx";

const FactCard = ({
  card: { backgroundColor, textColor, title, body, image }
}) => (
  <article
    css={[
      css`
        background: ${backgroundColor?.hex || `white`};
        color: ${textColor?.hex || `black`};
      `,
      tw`relative block w-full md:w-1/3 h-full md:h-auto p-8 pb-20 md:(p-12 pb-12) lg:(p-20 pb-20) text-center`
    ]}
  >
    <figure css={[tw`relative block w-3/4 mx-auto mb-4`]}>
      <Image image={image} />
    </figure>

    <T.Head _css={[tw`mb-4`]} font="5">
      {title}
    </T.Head>

    <T.Body _css={[tw`mb-4`]} font="2">
      {body}
    </T.Body>
  </article>
);

export default FactCard;
