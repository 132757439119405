/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React from "react";
import tw, { css, theme } from "twin.macro";
import FactCard from "~components/FactCard.jsx";
import SwiperCarousel from "~components/SwiperCarousel.jsx";
import { useMediaQuery } from "~hooks";

const FactCardsSection = ({ data }) => {
  const { cards } = data;

  const { isDesktop } = useMediaQuery();
  //

  return (
    <section css={[tw`w-full relative block`]}>
      {isDesktop ? (
        <div css={[tw`relative flex items-stretch`]}>
          {cards.map((card) => (
            <FactCard key={card._key} card={card} />
          ))}
        </div>
      ) : (
        <div
          css={[
            css`
              .swiper-slide {
                height: auto;
              }
            `,
            tw`relative w-full col-span-full`
          ]}
        >
          <SwiperCarousel
            nav={({ current }) => (
              <ul
                css={[
                  tw`w-full absolute bottom-10 flex items-center justify-center z-10`
                ]}
              >
                {cards.map((card, index) => (
                  <li
                    key={`${card._key}-nav`}
                    css={[
                      css`
                        width: 6px;
                        height: 6px;
                        margin: 0 2px;
                        background: ${current === index
                          ? theme`colors.black`
                          : `rgba(0, 0, 0, 0.2)`};
                      `,
                      tw`relative block rounded-full`
                    ]}
                  />
                ))}
              </ul>
            )}
            slides={cards.map((card) => (
              <FactCard key={card._key} card={card} />
            ))}
          />
        </div>
      )}
    </section>
  );
};

export default FactCardsSection;
