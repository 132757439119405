/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React from "react";
import tw, { css, theme } from "twin.macro";
import Grid from "~components/styles/Grid.jsx";
import Go from "~components/Go.jsx";
import * as T from "~components/styles/Typography.jsx";
import Button from "~components/Button.jsx";

const HeaderTextSection = ({ data }) => {
  const { backgroundColor, textColor, header, body, button } = data;

  //

  return (
    <section
      css={[
        css`
          background-color: ${backgroundColor?.hex || `white`};
          color: ${textColor?.hex || `black`};
        `,
        tw`relative block`
      ]}
    >
      <Grid node="article" _css={[tw`pb-12 md:pb-20`]}>
        <div
          css={[
            tw`col-span-full md:col-span-10 md:col-start-2 relative pt-12 md:pt-0`
          ]}
        >
          {header && <T.Head font="4">{header}</T.Head>}
        </div>

        <div
          css={[
            tw`col-span-full md:col-span-10 md:col-start-14 relative pt-10 md:pt-0`
          ]}
        >
          {body && (
            <T.Body font="1" _css={[tw`whitespace-pre-line`]}>
              {body}
            </T.Body>
          )}

          {button && (
            <Go
              _css={[tw`w-full md:w-auto mt-12 md:mt-12 block`]}
              to={button.link}
            >
              <Button
                _css={[tw`w-full md:w-auto`]}
                color="orange-dark"
                text={button.text}
                transparent
              />
            </Go>
          )}
        </div>
      </Grid>
    </section>
  );
};

export default HeaderTextSection;
