/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React from "react";
import tw, { css, theme } from "twin.macro";
import Grid from "~components/styles/Grid.jsx";
import * as T from "~components/styles/Typography.jsx";
import SwiperCarousel from "~components/SwiperCarousel.jsx";

const TextFourColumn = ({ data }) => {
  const { backgroundColor, textColor, header, subheader, points } = data;

  //

  return (
    <section
      css={[
        css`
          background: ${backgroundColor?.hex || `white`};
          color: ${textColor?.hex || `black`};
        `,
        tw`relative block`
      ]}
    >
      <header
        css={[
          css`
            @media screen and (max-width: 1024px) {
              background: ${textColor?.hex || `black`};
              color: ${backgroundColor?.hex || `white`};
            }
          `,
          tw`w-full relative pt-12 md:pt-16 pb-12`
        ]}
      >
        <T.Head _css={[tw`w-2/3 md:w-full mx-auto mb-4 text-center`]} font="2">
          {header}
        </T.Head>

        <T.Head _css={[tw`w-2/3 md:w-full mx-auto mt-4 text-center`]} font="4">
          {subheader}
        </T.Head>
      </header>

      <div css={[tw`hidden md:block`]}>
        <Grid _css={[tw`relative pt-3 pb-16 text-center`]}>
          <ul css={[tw`col-span-22 col-start-2 relative flex items-stretch`]}>
            {points.map((point, index) => (
              <li key={`point-${index}`} css={[tw`w-1/4 relative px-6`]}>
                <T.Body font="3">{point}</T.Body>
              </li>
            ))}
          </ul>
        </Grid>
      </div>

      <div css={[tw`md:hidden`]}>
        <SwiperCarousel
          nav={({ current }) => (
            <ul
              css={[
                tw`w-full relative flex items-center justify-center pt-8 pb-12`
              ]}
            >
              {points.map((_, index) => (
                <li
                  css={[
                    css`
                      width: 6px;
                      height: 6px;
                      margin: 0 2px;
                      background: ${current === index
                        ? theme`colors.red-dark`
                        : theme`colors.red-medium`};
                    `,
                    tw`relative block rounded-full`
                  ]}
                />
              ))}
            </ul>
          )}
          slides={points.map((point, index) => (
            <article
              key={`point-slide-${index}`}
              css={[tw`w-full relative pt-16 pr-12 pl-12`]}
            >
              <T.Body _css={[tw`text-center`]} font="2">
                {point}
              </T.Body>
            </article>
          ))}
        />
      </div>
    </section>
  );
};

export default TextFourColumn;
